.ask-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
    
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.ask-container.red{
    background-image: url("/src/assets/redBG.jpg");
}
.ask-container.green{
    background-image: url("/src/assets/greenBG.jpg");
}
.responseBox {
	display: flex;
	justify-content: start;
	align-items: start;
	flex-direction: column;
  height: auto;
	max-height: 60vh;
	width: 85vw;
	margin-bottom: 5vh;
	box-shadow: 0px 0px 6px;
	border-radius: 20px;
	position: relative;
	overflow: auto;
}
.ideaBox {
	background-color: rgba(255,255,255, 0.75);
	width: 100%;
	color: var(--textColor);
	font-family: var(--baseFont);
  position: relative;
  border-bottom: 1px groove black;
  text-align: center;
  height: fit-content;
}
.ideaBox.red,
.conversationImgDiv.red{
  border-color: red;
  color: var(--textColor);
}
.ideaBox.green,
.conversationImgDiv.green{
  border-color: green;
  color: var(--textColor);
}
.thinkingBox,
.avaliableBox{
  height: 100%;
  width: 100%;
	color: var(--textColor);
  background-color: rgba(255,255,255, 0.75);
	font-family: var(--baseFont);
  border-bottom: 1px groove black;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1111;
  min-height: 7vh;
}
.avaliableBox{
  font-size: 25px;
}
.lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    text-align: center;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div.thinkingRed{
    background: red;
  }
  .lds-facebook div.thinkingGreen{
    background: green;
  }
  .lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
  .savingBox {
    background-color: rgba(255,255,255, 0.85);
    width: 100%;
    color: var(--textColor);
    font-family: var(--baseFont);
    position: absolute;
    top: 0;
    text-align: center;
    z-index: 111;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.padding{
    margin: 0;
    padding: 15px;
    text-align: justify;
}
.bold{
    font-weight: bold;
}
.conversationDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85vw;
  gap: 3vw;
}
.conversationImgDiv {
	background-color: rgba(255,255,255, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;
  padding: 10px;
  gap: 5px;
  border: 1px solid;
  height: 80%;
  transition: all .25s ease;
}
.conversationImgDiv > img {
  width: 5vw;
  filter: drop-shadow(3px 3px 4px #000); 
  cursor: pointer;
}
#pipeImgBtn {
  width: 6.5vw;
}
.conversationImgDiv.green:hover {
  filter: drop-shadow(0px 0px 1px green); 
}
.conversationImgDiv.red:hover {
  filter: drop-shadow(0px 0px 1px red); 
}
.conversationImgDiv > p {
  margin: 0;
  color: var(--textColor);
}
.date {
	margin: 0;
	position: absolute;
  top: 12px;
  right: 15px;
	font-weight: bold;
}
.askBox {
	display: flex;
	gap: 10px;
	position: relative;
	background-color: rgba(255,255,255, 0.75);
	justify-content: center;
	align-items: center;
	padding: 10px;
	border-radius: 15px;
	box-shadow: 0px 0px 6px;
  height: 80%;
}
.askBox.hide{
    display: none;
}
.blurBack-chatBox{
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 40%;
    background-color: var(--lightColor);
}
.sendBtn {
	border: none;
	border-radius: 8px;
	padding: 10px;
  height: 100%;
  
	background-color: var(--darkerColor);
	color: var(--lightColor);
  transition: all .25s ease;
}
.sendBtn:hover{
  color: var(--darkerColor);
  background-color: var(--lightColor);
}
.ideaInp{
  width: 40vw;
  height: 6vw;
  padding: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-family: var(--baseFont);
  max-height: 8vw;
  max-width: 55vw;
  font-size: 20px;
}
.imgGenerated{
  width: 15vw;
  margin-left: 10px;
}
