:root {
	--primaryColor: #1F2022;
	--secondaryColor: #4c89c6;
	--thirdColor: rgb(40, 172, 202);
	--fourthColor: #CBFCFF;
	--lightColor: white;
	--darkColor: black;
	--darkerColor: #020102;
	--textColor: black;

	--mSize: 20px;
	--sSize: calc(var(--mSize) * 0.5);
	--lSize: calc(var(--lSize) * 1.5);
	--xlSize: calc(var(--mSize) * 2);
	--xxlSize: calc(var(--mSize) * 5);
	--titleFont: 'Carter One', cursive;
	--baseFont: 'Monoton';
}

body{
	background-image: url("/src/assets/background.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

	display: flex;
	align-items: center;
	flex-direction: column;
	height: 100vh;
	width: 100vw;
	justify-content: center;
}
body.redBG{
	background-image: url("/src/assets/redBG.jpg");
}
body.greenBG{
	background-image: url("/src/assets/greenBG.jpg");
}
.App{
	width: 100vw;
	height: 100vh;
	display: flex;
  	justify-content: center;
  	align-items: center;
}
.hide{
    display: none;
}