.chest-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	height: 100vh;
    width: 100vw;
}
.chestBoxes{
    display: flex;
    height: 100vh;
}
.imgDiv{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chestPic{
    width: 50vw;
    z-index: 1;
    height: 100%;
    position: relative;
}
.preNonSelectedDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--lightColor);
    height: 100vh;
    width: 50vw;
    position: absolute;
    top: 0;
    opacity: 35%;
    transition: all .35s ease-in-out;
    z-index: 99;
}
.preNonSelectedDiv:hover{
    opacity: 0;
}
.chestInfo{
    position: absolute;
    bottom: 5%;

    height: auto;
    width: auto;
    background-color: rgba(0, 0, 0, .7);
    color: var(--lightColor);
    border: 1px solid var(--lightColor);
    border-radius: 10px;
    padding: 20px;
    z-index: 100;
    transition: all .35s ease-in-out;

    opacity: 0;
}
.preNonSelectedDiv:hover ~ .chestInfo{
    /* width: 80%; */
    opacity: 100%;
    bottom: 25%;
}
.chestTitle{
    z-index: 100;
    
    position: absolute;
    top: 45%;

    transition: all .35s ease-in-out;
    width: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 1);
    padding: 10px 0px 10px 0px;
}
.redChest{
    color: red;
    border-top: 1px solid red;
    border-bottom: 1px solid red;
}
.greenChest{
    color: green;
    border-top: 1px solid green;
    border-bottom: 1px solid green;
}
.preNonSelectedDiv:hover ~ .chestTitle{
    /* opacity: 0%; */
    /* background-color: rgba(255, 255, 255, .5); */
    top: -22px;
}

.chestTitle:hover{
    top: -22px;
}
.chestTitle:hover ~ .chestInfo{
    opacity: 100%;
    bottom: 25%;
}
.chestInfo:hover{
    opacity: 100%;
    bottom: 25%;
}
.chestInfo:hover ~ .chestTitle{
    top: -22px;
}