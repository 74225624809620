.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	position: absolute;
	top: -1px;
}
.title{
	font-family: var(--titleFont);
	font-size: 40px;
	margin: 0;
	margin-top: 10px;
}
.navLink {
	text-decoration: none;
	color: var(--lightColor);
	transition: all .1s ease;
}
.chest-nav{
	width: 50px;
	padding-right: 5px;
}
.pipe-nav{
	width: 50px;
	padding-right: 5px;
}
.door-nav{
	width: 45px;
	padding-left: 10px;
}
.chestLink, .pipeLink, .signoutLink {
	background-color: rgba(255,255,255, 0.75);
	padding: 20px;
	width: 35px;
  	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-right: 1px solid black;
}
.chestLink,
.pipeLink{
	border-end-end-radius: 40px;
}
.signoutLink{
	border-end-start-radius: 40px;
	border-right: none;
	border-left: 1px solid black;
}
.hide{
	display: none;
}