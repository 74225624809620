.select-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	height: 100vh;
    width: 100vw;
    background-image: url("/src/assets/bg_selections.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.infoBox{
    position: absolute;
    top: 16%;
    transition: all 1s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.h1{
    margin: 0;
}
.gandalfsstaff{
    width: 40vw;
    scale: 1.2;
    z-index: 1001;
    position: relative;
    max-width: 600px;
    min-width: 400px;
}
.selectedInfo {
	padding: 15px;
	background-color: rgba(255,255,255, 0.75);
	border: 1px solid var(--darkerColor);
    border-top: none;
	height: auto;
    min-height: 30px;
	border-end-start-radius: 20px;
	border-end-end-radius: 20px;
	position: relative;
	top: -19px;
	box-shadow: 0px 1px 4px var(--darkerColor);
	width: 30vw;
    min-width: 200px;
    max-width: 400px;
	right: -3%;
    z-index: 1000;
    padding-bottom: 5px;

    text-align: left;
    font-family: var(--baseFont);
}
.selectedInfo > h2{
    text-align: center;
}
.infoSelected{
    font-size: var(--mSize);
    margin: 0;
    margin-bottom: 10px;
}
.cauldronsDiv{
    text-align: center;
}
.cauldron{
    width: 5vw;
}
.picBoxes{
    display: flex;
    justify-content: space-between;
  
    width: 100vw;
    position: absolute;
    bottom: 0;
}
.pic{
    width: 35vw;
    z-index: 1;
}
.redG{
    filter: drop-shadow(7px 3px 4px #000);
}
.redG:hover,
.redG.selected{
    filter: drop-shadow(7px 3px 4px #be0909);
}
.greenG{
    filter: drop-shadow(-7px 3px 4px #000);
}
.greenG:hover, 
.greenG.selected{
    filter: drop-shadow(-7px 3px 4px #27ab10);
}
.nameBox{
    position: absolute;
    background-color: rgba(255,255,255, 0.1);
    height: 7vh;
    width: 100%;
    bottom: 0;
    border-top: 1px solid var(--lightColor);
    font-family: var(--titleFont);

    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--lightColor);

    transition: all .25s ease-in-out;
}
.red{
    color: #be0909;
}
.nameBox.red{
    border-color: #be0909;
}
.green{
    color: #27ab10;
}
.nameBox.green{
    border-color: #27ab10;
}