.instructions{
    background-color: black;
    color: white;
    padding: 10px;
    margin: 0;
    border-radius: 20px;
    width: 20vw;
    max-width: 450px;
    min-width: 300px;
}
.instructions > svg{
    margin-right: 7px;
}