.notFound{
	height: 100vh;
    width: 100vw;
    background-image: url("/src/assets/gonchair.jpeg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.content-notfound{
	background-color: rgba(255,255,255, 0.75);
    box-shadow: 0px 1px 4px var(--darkerColor);
    border-radius: 20px;
    padding: 20px;

    position: absolute;
    left: 10%;
}
