.conversBoxes {
	display: flex;
	justify-content: start;
	align-items: center;
	flex-direction: column;
	height: auto;
	max-height: 80vh;
	overflow-y: auto;
	/* width: 95vw; */
	box-shadow: 0px 0px 6px;
	border-radius: 20px;
    margin-top: 65px;
}
.conversBox {
	background-color: rgba(255,255,255, 0.75);
	width: 100%;
	color: var(--textColor);
	font-family: var(--baseFont);
	position: relative;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
}
.conversDate{
    text-align: end;
    width: 100%;
    margin-right: 40px;
}
.conversTitle {
	font-size: 17px;
	position: absolute;
	left: 0px;
	padding: 22px;
}
.conversResponse{
    padding: 22px;
    margin: 0;
}