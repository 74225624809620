.homepage-container {
	display: flex;
	flex-direction: column;
	color: var(--textColor);
	font-family: var(--baseFont);
    background-color: rgba(255,255,255, 0.75);
	border-radius: 20px;
	padding: 25px;
    box-shadow: 0px 0px 10px 2px var(--darkerColor);

	gap: 15px;
	padding-bottom: 15px;
}
.homepage-container > h1 {
	text-align: center;
	margin: 0;
}
.home-options{
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.home-option {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	text-decoration: none;
	color: var(--textColor);
	height: 10vh;
}
.home-option p {
	margin: 0;
}
.chest{
	width: 6vh;
}
.pipe{
	width: 6.5vh;
}
.proveBtn{
	background-color: var(--darkerColor);
	box-shadow: -1px 2px 2px black;
	width: fit-content;
	padding: 10px;
	text-decoration: none;
	color: var(--lightColor);
	border-radius: 5px;

	transition: all .25s ease;
}
.proveBtn:hover{
	color: #5d5b5b;
	background-color: var(--lightColor);
}