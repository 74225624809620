body{
    background-image: url("/src/assets/homeBG.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.auth-container {
	display: flex;
	flex-direction: column;
	color: var(--textColor);
	font-family: var(--baseFont);
    background-color: rgba(255,255,255, 0.75);
	border-radius: 20px;
	padding: 35px;
    box-shadow: 0px 0px 10px 2px var(--darkerColor);
	position: relative;
}
.auth-container>h2{
    margin-top: 0px;
	text-align: center;
}
.auth-container > form {
	display: flex;
	flex-direction: column;
	text-align: left;
	gap: 15px;
	height: auto;
    font-weight: bold;
}
.auth-container > form input {
	border-radius: 20px;
	border: none;
	border-bottom: 1px solid black;
	height: 27px;
	padding-left: 10px;
    box-shadow: -1px 2px 2px;
    width: 20vw;
    max-width: 450px;
    min-width: 300px;
}
.auth-container > form button {
	border: none;
	border-bottom: 1px solid black;
	border-radius: 25px;
	height: 35px;
	background-color: var(--secondaryColor);
	color: var(--lightColor);
	transition: all .2s ease;
    box-shadow: -1px 2px 2px black;
    font-weight: bolder;
}
.auth-container > form button:hover {
    background-color: black;
    color: white;
    box-shadow: -1px 2px 2px var(--lightColor);
}
.auth-container > form button:disabled{
    cursor: not-allowed;
    background-color: darkgray;
	color: black;
    box-shadow: -1px 2px 2px black;
}
.auth-container >p>a{
    color: var(--textColor);
} 
/* Loading icon */
.loading{
	background-color: rgba(77, 77, 77, 0.5);
}
.lds-default {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
	scale: .75;
  }
  .lds-default div {
	position: absolute;
	width: 6px;
	height: 6px;
	background: var(--lightColor);
	border-radius: 50%;
	animation: lds-default 1.2s linear infinite;
  }
  .lds-default div:nth-child(1) {
	animation-delay: 0s;
	top: 37px;
	left: 66px;
  }
  .lds-default div:nth-child(2) {
	animation-delay: -0.1s;
	top: 22px;
	left: 62px;
  }
  .lds-default div:nth-child(3) {
	animation-delay: -0.2s;
	top: 11px;
	left: 52px;
  }
  .lds-default div:nth-child(4) {
	animation-delay: -0.3s;
	top: 7px;
	left: 37px;
  }
  .lds-default div:nth-child(5) {
	animation-delay: -0.4s;
	top: 11px;
	left: 22px;
  }
  .lds-default div:nth-child(6) {
	animation-delay: -0.5s;
	top: 22px;
	left: 11px;
  }
  .lds-default div:nth-child(7) {
	animation-delay: -0.6s;
	top: 37px;
	left: 7px;
  }
  .lds-default div:nth-child(8) {
	animation-delay: -0.7s;
	top: 52px;
	left: 11px;
  }
  .lds-default div:nth-child(9) {
	animation-delay: -0.8s;
	top: 62px;
	left: 22px;
  }
  .lds-default div:nth-child(10) {
	animation-delay: -0.9s;
	top: 66px;
	left: 37px;
  }
  .lds-default div:nth-child(11) {
	animation-delay: -1s;
	top: 62px;
	left: 52px;
  }
  .lds-default div:nth-child(12) {
	animation-delay: -1.1s;
	top: 52px;
	left: 62px;
  }
  @keyframes lds-default {
	0%, 20%, 80%, 100% {
	  transform: scale(1);
	}
	50% {
	  transform: scale(1.5);
	}
}
  
.valid{
    color: green;
}
.invalid{
    color: red;
}
.errmsg {
	font-weight: bold;
	text-decoration: underline;
	color: red;
	border-radius: 30px;
	text-align: center;
	margin-top: 0;
	text-decoration-color: var(--darkerColor);
}
.offscreen{
    position: absolute;
    top: -100%;
}
