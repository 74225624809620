.historyDetails-container{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
    
	height: 100vh;
    width: 100vw;
    background-image: url("/src/assets/chestSelectionBG.jpeg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.conversationsBoxes{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    height: auto;
    max-height: 60vh;
    overflow-y: auto;
    width: 85vw;
    box-shadow: 0px 0px 6px;
    border-radius: 20px;
    gap: 5px;
}
.conversationBox{
    background-color: rgba(255,255,255, 0.75);
	width: 100%;
	color: var(--textColor);
	font-family: var(--baseFont);
    position: relative;
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: space-between;
}
/* .conversationBox:not(:last-child){
    border-bottom: 5px groove black;
} */
.conversationTitle {
	max-width: 85%;
	margin-left: 40px;
	max-height: 25px;
    overflow: clip;
}
.conversationTitle>a{
    text-decoration: none;
    color: var(--darkColor);
}
.conversationTitle:hover{
    scale: 1.1;
}
.conversationDate{
    margin-right: 30px;
}